<template>
    <v-container fluid v-if="isLoggedIn == true" class="full-height" width="100%" style="padding-bottom: 0px;">
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <h3>
                    Mitarbeiter Mapping
                </h3>
            </v-col>
        </v-row>
        <v-row class="d-flex flex-row justify-center align-center">
            <v-col cols="12" sm="5" md="5" class="d-flex flex-column justify-center align-center" style="margin: 0; padding: 0;">
                <MappedEmployees @sourceEmployee="handleEmployeeForMapping" :echtIdNames="this.echtIdNames"
                    :mappedEmployee="mappedEmployee" :unmappedEmployee="unmappedEmployee"
                    :SelectedGroups="SelectedGroups" :defaultSelectedGroup="SelectedGroup" style="padding-right: 0;"
                    :deletedMasterEmployeeId="deletedMasterEmployeeId" :addedEmployee="addedEmployee" 
                    @mappedEmployeesMitarbeiterMappingKey="handleMappedEmployeesMitarbeiterMappingKey"
                    @finishedDelete="handleReset"></MappedEmployees>
            </v-col>
            <v-col cols="12" sm="1" md="1" class="d-flex flex-column justify-center align-center" style="margin: 0; padding: 0;">
                <v-btn color="green" class="mb-2" @click="openMappedDialog()">
                    <v-icon color="white">mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn color="red" @click="openUnmappedDialog()">
                    <v-icon color="white">mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="5" md="5" class="d-flex flex-column justify-center align-center" style="margin: 0; padding: 0;">
                <UnmappedEmployees @target_employee="handleEmployeeToMapping" :echtIdNames="this.echtIdNames"
                    :mappedEmployee="mappedEmployee" :unmappedEmployee="unmappedEmployee"
                    :SelectedGroups="SelectedGroups" :defaultSelectedGroup="SelectedGroup" style="padding-left: 0;"></UnmappedEmployees>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="6" class="d-flex flex-row justify-end align-right">
                <v-btn color="green" x-large @click="openAddEmployeeDialog()">Mitarbeiter hinzufügen</v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="d-flex flex-row justify-left align-left">
                <v-btn color="red" x-large @click="openDeleteEmployeeDialog()">Mitarbeiter löschen</v-btn>
            </v-col>
        </v-row>
        <v-row> 
            <v-col cols="12" sm="12" md="12">
                <p>HINWEIS: Die Änderungen werden erst am nächsten Tag im Dashboard sichtbar!</p>
            </v-col>
        </v-row>
        <AddEmployeeDialog ref="addShow" :companies="echtIdNames" :SelectedGroups="SelectedGroups" :defaultSelectedGroup="SelectedGroup" 
            @addedEmployee="handleAddedEmployee"></AddEmployeeDialog>
        <DeleteEmployeeDialog ref="deleteShow" :masterEmployeeToDelete="sourceEmployee" @deletedEmployee="handleDeletedEmployee"
            :echtId="SelectedGroup"></DeleteEmployeeDialog>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
        <MappingDialogView ref="mappedShow" @update_mapped="updateEmployeeMapping"></MappingDialogView>
        <UnmappingDialogView ref="unmappedShow" @update_unmapped="updateEmployeeUnmapping"></UnmappingDialogView>
    </v-container>
</template>
<style>
.full-height {
    height: calc(100vh - 80px);
}
</style>

<script>
import MappedEmployees from '@/components/employeeMapping/MappedEmployees.vue';
import UnmappedEmployees from '@/components/employeeMapping/UnmappedEmployees.vue';
import MappingDialogView from '@/components/dialogs/employeeMapping/MappingDialog.vue';
import UnmappingDialogView from '@/components/dialogs/employeeMapping/UnmappingDialog.vue';
import AddEmployeeDialog from '@/components/dialogs/employeeMapping/AddEmployeeDialog.vue';
import DeleteEmployeeDialog from '@/components/dialogs/employeeMapping/DeleteEmployeeDialog.vue';

import apiMixin from '@/mixins/apiMixin.js';
import PopupAlert from "@/components/popupAlert.vue";
import apiEmployeeService from '@/services/api/apiEmployeeService';


const emitter = require('tiny-emitter/instance');

export default
    {
        data() {
            return {
                addedEmployee: {},
                alertType: 'error',
                alertMessage: '',
                dialog: false,
                deletedMasterEmployeeId: '',
                echtIdNames: [],
                sourceEmployee: '',
                targetEmployee: '',
                mappedEmployee: {},
                unmappedEmployee: {},
                mappedEmployeesMitarbeiterMappingKey: [],
            }
        },
        name: "employee-mapping-view",

        mixins: [apiMixin],

        props: {
            isLoggedIn: Boolean,
            SelectedGroups: {
                type: Array,
                default: () => { }
            },
            SelectedGroup: {
                type: Number,
                default: null
            }
        },

        components: {
            MappedEmployees,
            UnmappedEmployees,
            PopupAlert,
            MappingDialogView,
            UnmappingDialogView,
            AddEmployeeDialog,
            DeleteEmployeeDialog
        },

        methods: {
            /**
             * Open Dialog for mapping an employee
             */
            openMappedDialog() {
                if (this.mappedEmployeesMitarbeiterMappingKey.includes(this.sourceEmployee.mitarbeiter_mapping_key)) {
                    this.newAlert(`Bitte wählen Sie einen Master Mitarbeiter aus, auf welchen zugeordnet werden soll `, 'error');
                    return;
                }
                if (this.sourceEmployee == '') {
                    this.newAlert(`Bitte wählen Sie einen Mitarbeiter auswählen, auf welchen zugeordnet werden soll `, 'error');
                    return;
                }
                if (this.targetEmployee == '') {
                    this.newAlert(`Bitte wählen Sie einen Mitarbeiter aus, welcher zugeordnet werden soll `, 'error');
                    return;
                }
                if (this.sourceEmployee.master_employee_id === undefined) {
                    this.newAlert("Es kann nur auf einen Master Mitarbeiter zugeordnet werden", 'error');
                    return;
                }
                this.$refs.mappedShow.openMappedDialog(this.sourceEmployee, this.targetEmployee);
            },

            /**
             * Open Dialog for unmapping an employee
             */
            openUnmappedDialog() {
                if (this.targetEmployee != '') {
                    this.newAlert(`Bitte wählen Sie keinen unzugeordneten Mitarbeiter `, 'error');
                    return;
                }
                if (this.sourceEmployee == '') {
                    this.newAlert(`Bitte wählen Sie einen Mitarbeiter aus, bei dem die Zuordnung aufgelöst werden soll `, 'error');
                    return;
                }
                // Check if the selected employee is a master employee
                if (this.sourceEmployee.mapped_employees_for_echt_employee_id !== undefined) {
                    this.newAlert(`Ein Master Mitarbeiter kann nicht unzugeordnet werden `, 'error');
                    return;
                }
                this.$refs.unmappedShow.openUnmappedDialog(this.sourceEmployee);
            },

            /**
             * Open Dialog for adding a master employee
             */
            openAddEmployeeDialog() {
                this.$refs.addShow.openAddEmployeeDialog();
            },

            /**
             * Open Dialog for deleting a master employee
             */
            openDeleteEmployeeDialog() {
                if (this.sourceEmployee == '') {
                    this.newAlert(`Bitte wählen Sie einen Mitarbeiter aus, welcher gelöscht werden soll `, 'error');
                    return;
                }
                if (this.sourceEmployee.mapped_employees_for_echt_employee_id.length > 0){
                    this.newAlert(`Ein Mitarbeiter, mit zugeordneten Mitarbeitern, kann nicht gelöscht werden `, 'error');
                    return;
                }
                this.$refs.deleteShow.openDeleteEmployeeDialog();
            },

            /**
             * Save the selected employee which is source of mapping
             * @param selectedItem 
             */
            handleEmployeeForMapping(selectedItem) {
                this.sourceEmployee = selectedItem
            },

            /**
             * Save the selected employee which is target of mapping
             * @param selectedItem 
             */
            handleEmployeeToMapping(selectedItem) {
                this.targetEmployee = selectedItem
            },

            /**
             * Save the deleted employee
             * @param deletedEmployee 
             */
            handleDeletedEmployee(deletedEmployee) {
                deletedEmployee.selected = false;
                this.deletedMasterEmployeeId = deletedEmployee.master_employee_id.toString();
            },

            /**
             * Save the added employee
             * @param addedEmployee 
             */
            handleAddedEmployee(addedEmployee) {
                this.addedEmployee = addedEmployee;
            },

            /**
             * Reset the deleted employee id
             */
            handleReset() {
                this.deletedMasterEmployeeId = '';
            },

            /**
             * Save the mapped employees MitarbeiterMappingKey
             * @param mappedEmployeesMitarbeiterMappingKey 
             */
            handleMappedEmployeesMitarbeiterMappingKey(mappedEmployeesMitarbeiterMappingKey) {
                this.mappedEmployeesMitarbeiterMappingKey = mappedEmployeesMitarbeiterMappingKey;
            },

            /**
             * Update employee after mapping action
             */
            async updateEmployeeMapping() {
                try {
                    await apiEmployeeService.updateEmployeeMapping(this.sourceEmployee, this.targetEmployee);
                    this.mappedEmployee = JSON.parse(JSON.stringify(this.targetEmployee));
                    this.mappedEmployee.master_name = this.sourceEmployee.master_name;
                    this.mappedEmployee.master_employee_id = this.sourceEmployee.master_employee_id;
                    this.mappedEmployee.disabled = false;
                    this.mappedEmployeesMitarbeiterMappingKey.push(this.targetEmployee.mitarbeiter_mapping_key);
                    this.sourceEmployee = '';
                    this.targetEmployee = '';
                    emitter.emit('reduceEmployeeCount');
                } catch (error) {
                    this.newAlert("Fehler beim Updaten des Mitarbeiters", "error");
                }
            },

            /**
             * Update employee after unmappig action
             */
            async updateEmployeeUnmapping() {
                try {
                    await apiEmployeeService.updateEmployeeUnmapping(this.sourceEmployee)
                    this.unmappedEmployee = this.sourceEmployee;
                    this.mappedEmployeesMitarbeiterMappingKey = this.mappedEmployeesMitarbeiterMappingKey.filter(item => String(item.mitarbeiter_mapping_key) != String(this.sourceEmployee.mitarbeiter_mapping_key));
                    this.sourceEmployee = '';
                    this.targetEmployee = '';
                    emitter.emit('increaseEmployeeCount');
                } catch (error) {
                    this.newAlert("Fehler beim Updaten des Mitarbeiters", "error");
                }
            },

            /**
             * Create a alert pop up message
             * @param message 
             * @param type 
             */
            newAlert(message, type) {
                this.alertMessage = message;
                this.alertType = type;
                this.$refs.alert.newAlert();
            },
        },
    }


</script>