import Vue from "vue";
import api from "../ApiBase";

export default {
    /**
     * Update employee after mapping action
     * @param {object} sourceEmployee 
     * @param {object} targetEmployee 
     */
    async updateEmployeeMapping(sourceEmployee, targetEmployee) {
        try {
            Vue.$log.debug("updateMapEmployeeMapping: ", sourceEmployee ," to ", targetEmployee);
            await api.patch('UpdateMapEmployee?id=' + sourceEmployee.master_employee_id + '&mitarbeiter_mapping_key=' 
                + targetEmployee.mitarbeiter_mapping_key + '&name=' + sourceEmployee.master_name+ '&id_copy=' 
                + sourceEmployee.master_employee_id);
            Vue.$log.debug("Successfully updated map employee mapping");
        } catch (error) {
            this.newAlert("Fehler beim Updaten des Mitarbeiters", "error");
        }
    },

    /**
     * Update employee after unmappig action
     * @param {object} sourceEmployee 
     */
    async updateEmployeeUnmapping(sourceEmployee) {
        try {
            Vue.$log.debug("updateUnmapEmployeeMapping: ", sourceEmployee);
            await api.patch('UpdateUnmapEmployee?mitarbeiter_mapping_key=' + sourceEmployee.mitarbeiter_mapping_key);
            Vue.$log.debug("Successfully updated unmap employee mapping");
        } catch (error) {
            this.newAlert("Fehler beim Updaten des Mitarbeiters", "error");
        }
    }
}